import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
          backgroundColor: "#f40",
          color: "white",
          padding: "1rem",
        }}
      >
        404 NOT FOUND
      </h1>
    </div>
    <div>
      <h3 class="has-text-centered has-text-weight-semibold"
        >
        お探しのページが見つかりませんでした。移動もしくは削除された場合があります。<br />
        また、URLにタイプミスがないか再度確認してください。<br />
      </h3>
    </div>
    <div
      className="full-width-image-container margin-top-0"
    >
      <h1 
        className="has-text-weight-bold is-size-1"
      >
      </h1>
    </div>
  </Layout>
);

export default NotFoundPage;
